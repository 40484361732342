<template>
  <div>
    <content-not-view
      v-if="
        (!subPermission.store && !isEdit) || (!subPermission.update && isEdit)
      "
    />
    <loading v-else-if="is_loading" />
    <b-card v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form-file
          ref="fileInput"
          accept=".jpg, .png, .gif"
          @change="profileImageHandler"
          v-show="false"
        />
        <b-form>
          <b-row class="d-flex" md="justify-content-center w-100">
            <b-col class="col-sm-11 col-md-11 col-lg-3 col-xl-3">
              <div class="img-preview mr-2">
                <img
                  :src="
                    isEdit && form_data.image
                      ? path
                      : path
                      ? path
                      : placeHolderImg
                  "
                  alt="Subject Image"
                />
                <div class="icon-holder" @click="openFileInput">
                  <img
                    :src="
                      isEdit && form_data.image && form_data.image.length
                        ? penIco
                        : addFile
                    "
                    alt="icon"
                  />
                </div>
              </div>
            </b-col>
            <div class="col-9">
              <!-- Name -->
              <b-col class="col-8">
                <b-form-group :label="$t('g.name')" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('g.name')"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form_data.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.placeholder.enterTheNameHere')"
                    />
                    <small class="text-danger">{{
                      errors[0]
                        ? $helpers.translateValidations(errors[0], $t("g.name"))
                        : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- ** Name -->
              <b-col class="col-8">
                <b-form-group
                  :label="
                    $t(
                      'g.subjects/add_subjecdt/inputs/lablel/IsAvailableSubject'
                    )
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Language"
                    rules="required"
                  >
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="form_data.is_available"
                        name="some-radio9"
                        value="1"
                        class="custom-control-success"
                      >
                        {{ $t("g.yes") }}</b-form-checkbox
                      >

                      <b-form-checkbox
                        v-model="form_data.is_available"
                        name="some-radio9"
                        value="0"
                        class="custom-control-danger mr-1 ml-1"
                      >
                        {{ $t("g.no") }}</b-form-checkbox
                      >
                    </div>
                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </div>
            <!-- Submit Button -->
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                :disabled="isButtonDisabled"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BFormFile,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import helper from "@/composables/helper.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "AddEducationLevel",
  data() {
    return {
      form_data: {
        name: null,
        is_available: "1",
        image: {},
      },
      isEdit: false,
      currentId: null,
      is_loading: true,
      archiveData: null,
      isButtonDisabled: false,
      path: null,
      placeHolderImg: require("@/assets/images/icons/placeholder-image.png"),
      penIco: require("@/assets/images/icons/pencil.svg"),
      addFile: require("@/assets/images/icons/Addfile.png"),
    };
  },
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BCard,
    BFormFile,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    loading,
    contentNotView,
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.currentId = this.$route.params.id;
      this.getdata();

      this.clearForm();
    } else {
      this.clearForm();
      this.is_loading = false;
    }
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "report-reasons");
      return this.$store.state.userData.sub_Permission;
    },
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler(newval, oldVal) {
        if (newval) {
          this.isEdit = true;
          this.currentId = newval;
          this.getdata();
        } else {
          this.clearForm();
          this.isEdit = false;
          this.is_loading = false;
        }
      },
    },
  },
  methods: {
    validationForm() {
      this.$refs.form.validate().then((succes) => {
        if (succes) {
          !this.isEdit ? this.submitAdd() : this.submitEdit();
        }
      });
    },
    submitAdd() {
      this.isButtonDisabled = true;

      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      if (!this.form_data.image) {
        formData.delete("image");
      }
      this.$http
        .post("admin/subjects", formData)
        .then((response) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: "SubjectsList" });
          }, 300);
        })
        .catch((error) => {
          this.isButtonDisabled = false;
          this.$helpers.handleError(error);
        });
    },
    submitEdit() {
      this.isButtonDisabled = true;
      const formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      if (this.archiveData["name"] === this.form_data["name"]) {
        formData.delete("name");
      }
      if (
        this.form_data["image"] &&
        this.archiveData["image"][0] &&
        this.archiveData["image"][0].path === this.form_data["image"].path
      ) {
        formData.delete("image");
      }
      this.$http
        .post(`admin/subjects/${this.currentId}?_method=put`, formData)
        .then((response) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: "SubjectsList" }, 300);
          });
        })
        .catch((error) => {
          this.isButtonDisabled = false;
          this.$helpers.handleError(error);
        });
    },
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    getdata() {
      this.$http
        .get(`admin/subjects/${this.currentId}`)
        .then((response) => {
          this.archiveData = response.data.data;
          for (let item in this.form_data) {
            this.form_data[item] = response.data.data[item];
            if ((item = "is_available")) {
              this.form_data[item] = response.data.data[item] ? "1" : "0";
            }
            if ((item = "image")) {
              this.form_data["image"] =
                response.data.data[item].length != 0
                  ? response.data.data[item][0]
                  : null;
            }
          }
          response.data.data["image"][0]
            ? (this.path = response.data?.data["image"][0].path)
            : null;

          this.is_loading = false;
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
    openFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    profileImageHandler(e) {
      const file = e.target.files[0];
      this.form_data.image = file;
      this.path = URL.createObjectURL(file);
    },
    clearForm() {
      for (let item in this.form_data) {
        if (item != "is_available") {
          this.form_data[item] = null;
        }
      }
      this.path = null;
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
