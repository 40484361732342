var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      (!_vm.subPermission.store && !_vm.isEdit) || (!_vm.subPermission.update && _vm.isEdit)
    )?_c('content-not-view'):(_vm.is_loading)?_c('loading'):_c('b-card',[_c('validation-observer',{ref:"form"},[_c('b-form-file',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileInput",attrs:{"accept":".jpg, .png, .gif"},on:{"change":_vm.profileImageHandler}}),_c('b-form',[_c('b-row',{staticClass:"d-flex",attrs:{"md":"justify-content-center w-100"}},[_c('b-col',{staticClass:"col-sm-11 col-md-11 col-lg-3 col-xl-3"},[_c('div',{staticClass:"img-preview mr-2"},[_c('img',{attrs:{"src":_vm.isEdit && _vm.form_data.image
                    ? _vm.path
                    : _vm.path
                    ? _vm.path
                    : _vm.placeHolderImg,"alt":"Subject Image"}}),_c('div',{staticClass:"icon-holder",on:{"click":_vm.openFileInput}},[_c('img',{attrs:{"src":_vm.isEdit && _vm.form_data.image && _vm.form_data.image.length
                      ? _vm.penIco
                      : _vm.addFile,"alt":"icon"}})])])]),_c('div',{staticClass:"col-9"},[_c('b-col',{staticClass:"col-8"},[_c('b-form-group',{attrs:{"label":_vm.$t('g.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('g.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheNameHere')},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$helpers.translateValidations(errors[0], _vm.$t("g.name")) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-8"},[_c('b-form-group',{attrs:{"label":_vm.$t(
                    'g.subjects/add_subjecdt/inputs/lablel/IsAvailableSubject'
                  )}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"some-radio9","value":"1"},model:{value:(_vm.form_data.is_available),callback:function ($$v) {_vm.$set(_vm.form_data, "is_available", $$v)},expression:"form_data.is_available"}},[_vm._v(" "+_vm._s(_vm.$t("g.yes")))]),_c('b-form-checkbox',{staticClass:"custom-control-danger mr-1 ml-1",attrs:{"name":"some-radio9","value":"0"},model:{value:(_vm.form_data.is_available),callback:function ($$v) {_vm.$set(_vm.form_data, "is_available", $$v)},expression:"form_data.is_available"}},[_vm._v(" "+_vm._s(_vm.$t("g.no")))])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":_vm.isEdit ? 'success' : 'primary',"type":"submit","disabled":_vm.isButtonDisabled},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }